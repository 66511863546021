import React, { Component } from 'react';
import './FlipSlideshow.css';
import TextAnimation from './TextAnimation';
import './TextAnimation.css';
import './Style.css';

class FlipSlideshow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardAmount: 4,
      flipSpeed: 1500,
      currentCard: 1,
      isFlipped: false,
      textContent: [
        "Posing from the bed, wearing red thong",
        "Blonde hair walking outdoor, slim body",
        "Blonde hair walking outdoor, slim body",
        "Posing from the bed, wearing red thong",
      ],
      textAnimationKey: 0,
    };
  }

  componentDidMount() {
    // Start the 3D flip slideshow
    this.startSlideshow();
  }

  componentWillUnmount() {
    clearInterval(this.slideshowInterval);
  }

  startSlideshow() {
    // Delay each slide by 6 seconds
    const slideDelay = 6000;

    this.slideshowInterval = setInterval(() => {
      this.setState((prevState) => ({
        currentCard: prevState.currentCard === prevState.cardAmount ? 1 : prevState.currentCard + 1,
        isFlipped: !prevState.isFlipped,
        textAnimationKey: prevState.textAnimationKey + 1, // Increment key to re-mount TextAnimation
      }));
    }, this.state.flipSpeed + slideDelay);
  }

  render() {
    const { currentCard, isFlipped, textContent, textAnimationKey } = this.state;

    return (
      <div className="cards-container">
        <div className={`cards ${isFlipped ? 'is-flipped' : ''}`}>
          <div className="cards__container">
            <div className={`cards__front card-${currentCard}`}>
              <TextAnimation text={textContent[currentCard - 1]} key={textAnimationKey} />
            </div>
            <div className={`cards__back card-${currentCard === 4 ? 1 : currentCard + 1}`}>
              <TextAnimation text={textContent[currentCard - 1]} key={textAnimationKey} />
            </div>
          </div>
        </div>
        {/* ... Rest of your code ... */}
      </div>
    );
  }
}

export default FlipSlideshow;
