import React from "react";
import "./before-login.css";
import "./Style.css";
import { useNavigate } from "react-router-dom";
import FooterNav from './FooterNav'; // Make sure to provide the correct path to your FooterNav component
import FAQSection from './faq';
import FlipSlide from "./FlipSlideshow";

const Before = ({ setLoginUser, user }) => {
  const Navigate = useNavigate();

  return (
    <body>
      <div className="before-login">
        {/* Home Layer Start From Here */}
        <section id="home">
          <div className="hero-section">
            <div className="dankest"></div>
            <FooterNav /> {/* Include the FooterNav component */}

            <div className="hero-content">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="hero-text">
                      <h1>Generate Realistic images with AI</h1>
                      <p>
                        Create realistic imagination images instantly, using just a text prompt
                        Your imagination girl become a reality with Deepictures AI Deep image generator.. </p>
                      <div className="hero-button-dekstop">
                        <button type="button" className="btn-hover color-4" onClick={() => Navigate("/Generate")}>
                          Free trial now
                          <i className="fas fa-arrow-right" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <FlipSlide />
                  </div>
                </div>
              </div>
              <div className="hero-button">
                <button type="button" className="btn-hover color-4" onClick={() => Navigate("/Generate")}>
                  Free trial now
                  <i className="fas fa-arrow-right" />
                </button>
              </div>
            </div>
          </div>
        </section>
        {/* Home Layer End From Here */}

        {/*  About Us Section START from here */}
        <section id="About-us">
          <div className="container">
            <div className="row">
              <p className="About-us-mob-text">Pure Relasitic</p>
              <div className="About-us-mob-img col-lg-6">
                <img className="Center" src={require('./assets/images/Samples-hero..webp')} alt="About-img" />
              </div>
              <div className="col-lg-6 About-us-left Center">
                <h2 className="title">
                  Generate Real images that Seems Rare
                </h2>
                <p className="des">
                  Deepictures Generate more realistic images that look very real! You are just a few steps away from generating pure realistic images with simple methods
                </p>
                <div className="About-hero-btn">
                  <button className="btn-hover color-4" type="button" onClick={() => Navigate("/Subscribe")}>
                    Free trail now
                    <i className="fas fa-arrow-right" />
                  </button>
                </div>
              </div>
              <div className="col-lg-6 About-us-right">
                <div className="About-us-right-img">
                  <img className="Center" src={require('./assets/images/Samples-hero..webp')} alt="About-img" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*  About Us Section END from here */}


        <div className="section-header text-center hei-180">
          <h2>How to Get AI Generated Images in 3 Steps?</h2>
        </div>
        {/*  How it works steps Section START from here */}
        <section id="How-it-works">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-4 Center">
                <div className="steps-col">
                  <div className="row-number">
                    <box className="box-transparent">
                      <img className="icon" src={require('./assets/images/sample-hero-1..webp')} alt="simple-steps" />
                    </box>
                  </div>
                  <div className="steps-content">
                    <div className="steps-title">
                      <span>1. Choose your Lookalike</span>
                    </div>
                    <s className="description">
                      Choose Model Lookalike AI character or Upload your own look to Generate AI images
                    </s>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 Center">
                <div className="steps-col">
                  <div className="row-number">
                    <box className="box">
                      <div className="col">
                        <div className="" id="col-loader">
                          <span />
                          <span />
                          <span />
                        </div>
                      </div>
                    </box>
                  </div>
                  <div className="steps-content">
                    <div className="steps-title">
                      <span>2. AI starts generating</span>
                    </div>
                    <s className="description">
                      Our AI will start learning from your prompts and lookalike and create an AI image of you.
                    </s>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 Center">
                <div className="steps-col">
                  <div className="row-number">
                    <box className="box-transparent">
                      <img className="icon" src={require('./assets/images/models..webp')} alt="simple-steps" />
                    </box>
                  </div>
                  <div className="steps-content">
                    <div className="steps-title">
                      <span>3. Create unlimited photos</span>
                    </div>
                    <s className="description">
                      You can now create unlimited AI images without any barrier
                    </s>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*  How it works steps Section END from here */}

        {/*  About Us Section START from here */}
        <section id="About-us2">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 About-us-right-img2">
                <img className="Center" src={require('./assets/images/about-hero..webp')} alt="about-us" />
              </div>
              <div className="col-lg-6 About-us-left Center">
                <h2 className="title">Generate Your own Real Deep clone</h2>
                <p className="des">
                  Generate Unlimited AI Images of your models After premium there is no restriction or terms and conditions to stop your generation after purchasing premium
                </p>
                <div className="About-hero-btn">
                  <button className="btn-hover color-4" type="button" onClick={() => Navigate("/Subscribe")}>
                    Free trail now
                    <i className="fas fa-arrow-right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*  About Us Section END from here */}

        {/*  FAQ QNA Section START from here */}
        <FAQSection />

        {/*  FOOTER Section START from here */}
        <footer className="site-footer">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <p className="copyright-text">
                  Copyright © 2023 All Rights Reserved by
                  <a href="/">Deep Pvt</a>
                </p>
              </div>
            </div>
          </div>
        </footer>

        {/*  FOOTER Section END from here */}


      </div>
    </body>
  );
};

export default Before;
