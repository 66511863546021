import React from 'react';
import './PrivacyPolicy.css'; // Import your CSS file
import { Helmet } from 'react-helmet';
import FooterNav from '../FooterNav/FooterNav';

const email = 'Feedback@deepictures.com';
const subject = 'Privacy Policy Inquiry';

const sendEmail = () => {
    window.location.href = `mailto:${email}?subject=${subject}`;
};

function PrivacyPolicy() {
    return (
        <div className="Privacy-Policy">
            <Helmet>
                <title>Privacy Policy - Deepictures</title>
                <meta name="description" content="Read our Privacy Policy to understand how we collect, use, and protect your personal information." /> {/* Set the meta description */}
            </Helmet>
            <FooterNav /> {/* Include the FooterNav component */}
            <div className="container mt-5">
                <h1 className="display-4 text-center">Privacy Policy</h1>

                <div className="privacy-policy-content">
                    <div className="privacy-policy-paragraph">
                        <p className="span">
                            Deepictures (we, us, or our) is pleased to have you. We respect your right to privacy and are devoted to keeping your personal information safe. When you use our website and services, this Privacy Policy will explain how we collect, use, disclose, and protect your personal data.
                        </p>
                        <p className="span">
                            To better understand what we do, carefully read this notice. Please feel free to contact us if there is anything about our privacy policy that you do not understand. Information on how to do so is provided at the conclusion of this policy. Please take note that our Terms of Service also apply to your usage of our APP.
                        </p>
                    </div>
                    <h2>1. Information We Collect</h2>
                    <p>
                        We may collect personal information that you provide directly to us while registering, such as your name, email address, or other identifying information when you use our website or contact us. Additionally, we may automatically collect information about how you interact with our website, including your IP address, browser type, the pages you visit, and the time and date of your visit.
                    </p>

                    <p>
                        Additionally, to enhance your experience and better understand how our website is used, we automatically collect certain information about your interactions with us. Here's a breakdown of the data we collect:
                    </p>

                    <ol>
                        <li>
                            <strong>Personal Information:</strong> This includes data you voluntarily provide when you register, such as your name, email address, and other identifying information. Rest assured that this information is securely stored and not shared with any third parties.
                        </li>

                        <li>
                            <strong>Usage Information:</strong> To improve our services, we automatically gather data about your interactions with our website. This may include your:
                            <ul>
                                <li>
                                    <em>IP Address:</em> We collect your IP address to help diagnose problems with our server, administer our website, and track usage patterns. This information is solely used for internal purposes and is not disclosed to any external parties.
                                </li>
                                <li>
                                    <em>Browser Type:</em> We record the type of browser you are using to ensure our website functions properly across different platforms.
                                </li>
                                <li>
                                    <em>Pages Visited:</em> We track the specific pages you visit on our website to analyze user behavior and enhance our content accordingly.
                                </li>
                                <li>
                                    <em>Time and Date of Visit:</em> We log the time and date of your visit to help us understand peak usage periods and optimize our services.
                                </li>
                            </ul>
                        </li>
                    </ol>

                    <p>
                        It's important to reiterate that all the data we collect, whether provided directly by you or gathered automatically, is used exclusively to improve our services, personalize your experience, and ensure the security of your data.
                    </p>

                    <h2>2. How We Use Your Information</h2>
                    <p>
                        At Deepictures, we utilize the data we gather for a variety of objectives, all of which are designed to give you a secure, personalised, and better experience when using our services. You may relax knowing that your data is treated with the highest care and regard for your privacy.
                    </p>

                    <p>
                        Here's how we use the information we collect:
                    </p>

                    <ol>
                        <li>
                            <strong>Providing the Services You Request:</strong> The data you provide enables us to deliver the services you request effectively. Whether it's creating custom images or providing support, your information helps us fulfill your requests promptly and accurately.
                        </li>

                        <li>
                            <strong>Communicating with You:</strong> We may use your contact information, such as your email address, to communicate with you regarding your account, updates to our services, and important information related to your interactions with us. Our aim is to keep you informed and provide a seamless user experience.
                        </li>

                        <li>
                            <strong>Improving Our Services:</strong> Your data plays a crucial role in helping us enhance our services. By analyzing usage patterns, user feedback, and your interactions with our website, we gain valuable insights that enable us to make continuous improvements. These enhancements are designed to make your experience more efficient, enjoyable, and tailored to your needs.
                        </li>
                    </ol>

                    <p>
                        We want to assure you that we handle your data responsibly and ethically. Your personal information is never shared with third parties, and it is stored securely in accordance with our data protection measures.
                    </p>

                    <p>
                        Your trust in us is extremely important to us, and we are dedicated to preserving the privacy and security of your data during your interaction with us.
                    </p>

                    <h2>3. Cookies and Similar Technologies</h2>
                    <p>
                        In order to improve your surfing experience and provide you with a more individualised service, [Your Website Name] makes use of cookies and other similar technologies. We must be open and honest about the operation of these technologies and how they will help you.
                    </p>

                    <p>
                        Here's a closer look at how we utilise cookies and related technologies:
                    </p>

                    <ol>
                        <li>
                            <strong>Enhancing Your Experience:</strong> Cookies are small text files that are stored on your device when you visit our website. They serve various purposes, including remembering your preferences, such as language settings and login information. These cookies make it easier and more convenient for you to navigate our website and access its features.
                        </li>

                        <li>
                            <strong>Collecting Usage Data:</strong> We use cookies to collect data about your usage of our website. This includes information such as the pages you visit, the duration of your visit, and the specific features you interact with. This data is invaluable in helping us understand how users engage with our platform and make necessary improvements to enhance your experience.
                        </li>

                        <li>
                            <strong>Tracking Technologies:</strong> In addition to cookies, we may also employ other tracking technologies like web beacons and pixels. These technologies help us gather insights into the effectiveness of our marketing efforts, allowing us to tailor our content and promotions to better suit your interests.
                        </li>
                    </ol>

                    <p>
                        It's essential to emphasize that you have control over cookies. You can manage them through your browser settings, allowing you to accept or reject cookies as you see fit. Please note that disabling certain cookies may impact your ability to access certain features or enjoy a fully personalized experience on our website.
                    </p>

                    <p>
                        Your privacy remains paramount to us, and we are committed to respecting your choices when it comes to cookie preferences. You can always find more information about our use of cookies and your options in our dedicated <a href="/cookie-policy">Cookie Policy</a>.
                    </p>

                    <h2>4. Third-Party Links and Services</h2>
                    <p>
                        While browsing our website, you may come across links to third-party websites and services that are not operated by us. It's important to understand that we do not have control over the content or privacy practices of these external websites.
                    </p>

                    <p>
                        We encourage you to review the privacy policies of any third-party websites you visit to ensure your data privacy and security. We disclaim any responsibility or liability for the practices of third parties, as they operate independently from us.
                    </p>

                    <h2>5. Security</h2>
                    <p>
                        Safeguarding your personal information is a top priority at Deepictures. We take reasonable measures to protect your data from unauthorized access, disclosure, alteration, or destruction.
                    </p>

                    <p>
                        It's crucial to understand that no data transmission via the internet can be guaranteed to be 100% safe, despite the fact that we use strict security methods. We continuously assess and update our security measures to stay at the forefront of data protection.
                    </p>

                    <h2>6. Your Choices</h2>
                    <p>
                        We value your control over your personal information. You have the ability to access, update, or delete your data by reaching out to us at{' '}
                        <a href={`mailto:${email}?subject=${subject}`} onClick={sendEmail}>
                            {email}
                        </a>. Additionally, you may choose to opt-out of receiving promotional emails from us at any time.
                    </p>

                    <p>
                        Your preferences matter to us, and we are committed to respecting and fulfilling your choices regarding your data.
                    </p>

                    <h2>7. Changes to this Privacy Policy</h2>
                    <p>
                        We could revise this privacy statement from time to time to reflect changes to our procedures or to satisfy regulatory obligations. Any changes will have an updated effective date and be reported on this page.
                    </p>

                    <p>
                        Your continued use of our website after such changes will constitute your consent to the updated policy. We encourage you to review this Privacy Policy regularly to stay informed about how your data is handled.
                    </p>

                    <h2>8. Contact Us</h2>
                    <p>
                        If you have any questions, concerns, or requests regarding this Privacy Policy or our data practices, please don't hesitate to contact us at{' '}
                        <a href={`mailto:${email}?subject=${subject}`} onClick={sendEmail}>
                            {email}
                        </a>. We are here to assist you and address any inquiries you may have.
                    </p>
                </div>
            </div>
            {/* FOOTER Section START from here */}
            <footer className="gen-site-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <p className="copyright-text">
                                Copyright © 2023 All Rights Reserved by
                                <a href="/">Deep Pvt</a>
                            </p>
                        </div>
                    </div>
                </div>
            </footer>

            {/* FOOTER Section END from here */}
        </div>
    );
}

export default PrivacyPolicy;
