import React from 'react';
import './TermsOfService.css'; // Import your CSS file
import { Helmet } from 'react-helmet';
import FooterNav from '../FooterNav/FooterNav';

const email = 'Feedback@deepictures.com';
const subject = 'Terms of Service Inquiry';

const sendEmail = () => {
    window.location.href = `mailto:${email}?subject=${subject}`;
};

function TermsOfService() {
    return (
        <div className="Terms-Of-Service">
            <Helmet>
                <title>Terms of Service - Deepictures</title>
                <meta name="description" content="Read and understand our Terms of Service before using our website and services." /> {/* Set the meta description */}
            </Helmet>
            <FooterNav /> {/* Include the FooterNav component */}
            <div className="container mt-5">
                <h1 className="display-4 text-center">Terms of Service</h1>

                <div className="terms-of-service-content">
                    <div className="terms-of-service-paragraph">
                        <p className="span">
                            Deepictures (we, us, or our) is pleased to have you. All things visually creative, we're your partner! We're delighted to have you on board and want to make sure your use of our website and services is easy and pleasurable. We sincerely request that you go over our Terms of Service, which serve as the ground rules for our digital playground
                            At Deepictures, we take your privacy and data security seriously. We want you to be fully informed about how we operate, so if you ever have any questions or concerns, don't hesitate to reach out to us. We're here to help! </p>
                        <p className="span">
                            These TOS (Terms Of Service) agreements are more than just words; they represent a contract between you and us that will influence how we work and produce together. You are creating a contract with Deepictures that is enforceable by utilising our website and services. Let's get into the specifics now so that you are fully aware of your rights and obligations as we begin on this creative adventure together.
                        </p>
                    </div>

                    <h2>1. Account</h2>
                    <p>At Deepictures, we strongly believe in the value of collaboration, and your account serves as your key to a world of artistic possibilities. The important information about your account is provided here, enabling you to explore, interact with others, and create within our active community:</p>
                    <p>
                        <strong>1.1 Account Creation:</strong> With the creation of your own account, your journey begins. You might need to create an account in order to use some features of our services. During the registration procedure, we respectfully ask that you give us true and complete information. Maintaining the security of your account credentials is essential since your account serves as a digital representation of yourself. Your creative endeavours are steered by you, and you are accountable for any actions taken while using your account. Your account is exclusively your property, and you are in charge of any usage and results that may result from it. </p>
                    <p>
                        <p>In the event that you violate these Terms of Service in any way, including but not limited to failing to keep your user account's information current and accurate, we reserve the right to suspend or terminate your user account in our sole discretion.</p>
                        <strong>1.2 Subscription:</strong> We reserve the right to offer some Services that will be only available to premium subscribers exclusively, at our sole discretion. The subscription will start once the initial payment has been made, and the payment should be made in accordance with the fee conditions that were listed on the site at the time of the purchase. All fees, charges, and taxes (if mandated by law) connected to the transaction are your responsibility to pay.
                    </p>
                    <p>a. If you decide not to renew, you can cancel it on your own using the instructions provided below in the Stripe. Additionally, you may email us a request by logging into your deepictures account Visit <a href='/cancellation'>Cancellation & Refund</a> Page for more!</p>
                    <p>b. An image that was created for you used server, GPU, and electrical resources. It was a personalised product. If you never create a picture, the only option to receive a refund is in such case. So if you intend to request a refund, end the process here. You will start a dispute if you create at least 1 image and want a refund. Your access to our website's free and premium services will be permanently restricted following the dispute. Utilise the free version if you only want to do a test.</p>
                    <p>c. If your refund has been delayed or is missing (if applicable), check your bank account once more first. Then get in touch with your credit card provider because it can take some time for the refund to appear on your account. Contact your bank next. Before a refund is posted, there is frequently a processing period.</p>
                    <p>If you've done all of this and you still have not received your refund yet, please contact us via the Feedback@deepictures.com</p>
                    <p>Our mission is to empower your creativity, and your account is the compass guiding you through this exciting journey. Should you ever have questions or need assistance, our support team is here to steer you in the right direction. Happy creating!</p>
                    <p>In the interim, we retain the right, in our sole discretion, to suspend or terminate your user account and/or user ID, or otherwise withdraw your access to the APP or Services that we provide, at any time for reasons or suspicions that suggest you have violated the provisions of the TOS or Privacy Policy. You acknowledge that we are not responsible for depriving you or any third party of the use of the APP, the content, or the Services we provide, or for any other change in the price of third-party Services or other fees, or for the suspension or termination of your user account and/or user ID.</p>

                    <h2>2. Legal Capacity</h2>
                    <p>
                        You must have the legal capacity to use our services. If you are under the legal age, you must obtain the necessary consent from a parent or guardian to use our services. By using our services, you represent and warrant that you have the legal capacity to enter into these terms.
                    </p>
                    <h2>3. Intellectual Property</h2>
                    <p>
                        <strong>3.1 Ownership:</strong> All content and materials on our website are our intellectual property and protected by copyright and other laws. You may not use our content for commercial purposes without our consent. However, you may use our services to generate content for your personal or non-commercial use.
                    </p>

                    <p>
                        <strong>3.2 User-Generated Content:</strong> Any content you upload or generate using our services remains your property. However, by using our services, you grant us a non-exclusive, royalty-free license to use, modify, and distribute your content for the purpose of providing our services. You retain full ownership of your content and can remove it at any time.
                    </p>

                    <h2>4. Your Contents</h2>
                    <p>
                        <strong>4.1 Responsibility:</strong> You are responsible for the content you upload or generate using our services. This includes ensuring that your content complies with applicable laws and regulations and does not infringe on the rights of others. We do not endorse or verify the accuracy of user-generated content.
                    </p>

                    <h2>5. Restrictions on Conduct and Content</h2>
                    <p>
                        <strong>5.1 Prohibited Activities:</strong> You agree not to engage in any activities that violate our terms, including using our services for illegal or harmful purposes, attempting to access unauthorized areas of our website, or disrupting the functionality of our services. We reserve the right to suspend or terminate accounts that engage in prohibited activities.
                    </p>

                    <p>
                        <strong>5.2 Content Restrictions:</strong> You may not upload or generate content that is offensive, inappropriate, or violates the rights of others, including content that is defamatory, infringing, or discriminatory. We have a zero-tolerance policy for such content and may remove or disable access to it.
                    </p>

                    <h2>6. Claims of Infringement</h2>
                    <p>
                        We respect the intellectual property rights of others. If you believe your intellectual property rights have been infringed by a user of our services, please contact us to report the infringement. We will promptly investigate and take appropriate action.
                    </p>

                    <h2>7. Limitation of Liability</h2>
                    <p>
                        <strong>7.1 Usage Disclaimer:</strong> While we strive to provide accurate and reliable information and image generation through our services, we make no warranties or representations about the accuracy or quality of the generated content. Your use of our services is at your own risk, and we will not be liable for any damages or losses arising from your use of our website or services.
                    </p>

                    <p>
                        <strong>7.2 Service Availability:</strong> We aim to provide uninterrupted access to our services, but we do not guarantee that our services will be available at all times. We are not responsible for any disruptions, outages, or technical issues that may occur.
                    </p>

                    <h2>8. Links to Third-Party Websites and Services</h2>
                    <p>
                        Our website may contain links to third-party websites and services. These links are provided for your convenience, but we do not control or endorse the content or practices of these third parties. You should review their privacy policies and terms of service when accessing their websites or services.
                    </p>

                    <h2>9. Governing Law; Dispute Resolution</h2>
                    <p>
                        These Terms of Service are governed by the laws of [Your Jurisdiction]. Any disputes or claims arising from these terms or your use of our services will be resolved through arbitration or in a court of competent jurisdiction, as specified in the dispute resolution section.
                    </p>

                    <p>
                        We reserve the right to terminate or suspend your account or access to our services at our discretion, especially if you violate these terms. You may not assign or transfer your rights and obligations under these terms without our prior written consent.
                    </p>

                    <p>
                        This section typically includes general provisions such as the entire agreement, waiver of rights, severability, and the fact that these terms constitute the entire agreement between you and us regarding your use of our services.
                    </p>

                    <h2>10. Changes to this TOS</h2>
                    <p>We retain the right to alter or modify these Terms of Service from time to time at our sole discretion, and we may provide you notice of changes by making the updated version of these Terms of Service available through the APP. Changes made will be effective right away. To ensure you are familiar with the most recent version of this TOS, please check back frequently. If the amended TOS are unacceptable to you, you have the right to discontinue using the APP immediately. By continuing to access or use the APP following the posting of any modifications to this TOS, you signify your acceptance with such changes.</p>
                    <p>We have the right to alter the scope of the Services described in this document as well as the relevant billing structure at any time, for any reason, and without prior warning. We have the right to completely halt or limit the supply of the Services to a specific user. We still have the right to stop offering and/or supporting the Services without giving any prior warning.</p>
                    <h2>11. Contact Us</h2>
                    <p>
                        If you have any questions, concerns, or requests regarding this Privacy Policy or our data practices, please don't hesitate to contact us at{' '}
                        <a href={`mailto:${email}?subject=${subject}`} onClick={sendEmail}>
                            {email}
                        </a>. We are here to assist you and address any inquiries you may have.
                    </p>
                </div>
            </div>
            {/* FOOTER Section START from here */}
            <footer className="gen-site-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <p className="copyright-text">
                                Copyright © 2023 All Rights Reserved by
                                <a href="/">Deep Pvt</a>
                            </p>
                        </div>
                    </div>
                </div>
            </footer>

            {/* FOOTER Section END from here */}
        </div>
    );
}

export default TermsOfService;
