import './App.css';
import Generate from './Components/Generate/Generate';
import Before from "./Components/before-login/before-login";
import Login from "./Components/login/login";
import Register from "./Components/register/register";
import Subscribe from './Components/Subscribe/Subscribe';
import Payment from './Components/Subscribe/Payment';
import Success from './Components/Subscribe/Success';
import Cancel from './Components/Subscribe/Cancel';
import Generation from './Components/Generate/OtherPage';
import PrivacyPolicy from './Components/PrivacyPolicy/PrivacyPolicy';
import TermsofService from './Components/TermsOfService/TermsOfService';
import Cancellation from './Components/Cancellation/Cancellation';
import CustomerSupport from './Components/CustomerSupport/CustomerSupport';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function App() {
  return (
    <div className="App">
	
	 <Helmet>
        <title>Deep Image Generator - Realistic NSFW Image Generator From Text</title>
        <meta name="description" content="Deepictures is a realistic image generator that look very real, You are just a few steps away from generating pure realistic images with simple methods." />
        <meta name="keywords" content="Deep Image Generator, NSFW, Realistic Image Generator, Generate Deep Images, Realistic art, Realistic Picture Generator." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta property="og:title" content="Deepictures - AI Image Generator" />
        <meta property="og:description" content="Deepictures is a realistic image generator that look very real, You are just a few steps away from generating pure realistic images with simple methods." />
        <meta property="og:image" content="/__static/images/Deepictures-AI-Image-Generator-92G831.png" />
        <meta property="og:image:alt" content="Realistic Image Generator, AI image generator, Deep Image Generator, NFSW photos generator."></meta>
        <meta property="og:site_name" content="Deepictures"></meta>
        <meta property="og:type" content="website"></meta>
        <link rel="canonical" href="https://deepictures.com" />
      </Helmet>
	  
      <div className="content">
        <Router>
          <Routes>
            <Route path="/" element={<Before />} />
            <Route path="/Generate" element={<Generate />} />
            <Route path="/Generation" element={<Generation />} />
            <Route path="/Subscribe" element={<Subscribe />} />
            <Route path="/Payment" element={<Payment />} />
            <Route path="/Login" element={<Login />} />
            <Route path="/Register" element={<Register />} />
            <Route path="/success" element={<Success />} />
            <Route path="/cancel" element={<Cancel />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-Service" element={<TermsofService />} />
            <Route path="/cancellation" element={<Cancellation />} />
            <Route path="/support" element={<CustomerSupport />} />
            {/*catch-all route for unknown paths */}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Router>
      </div>
    </div>
  );
}

export default App;
