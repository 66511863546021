import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/database"

const firebaseConfig = {
  apiKey: "AIzaSyDbNo2_aH6Pl9DANyZ_QlxVZOBQ62_2xmA",
  authDomain: "stripe-subscription-abb4d.firebaseapp.com",
  projectId: "stripe-subscription-abb4d",
  storageBucket: "stripe-subscription-abb4d.appspot.com",
  messagingSenderId: "624208610524",
  appId: "1:624208610524:web:5c314f2a4b455157a5e019"
};


if(!firebase.apps.length){
    firebase.initializeApp(firebaseConfig)
}

export default firebase