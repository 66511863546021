import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import firebase from "../firebase/firebaseConfig";
import "./login.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faKey } from "@fortawesome/free-solid-svg-icons";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(""); // Add an error state

  const Navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    try {
      setError(""); // Clear any previous error messages

      if (!email || !password) {
        setError("Please fill in all fields"); // Set error message
        return;
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        setError("Please provide a valid email"); // Set error message
        return;
      }

      setLoading(true);
      const response = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password);

      if (response.user) {
        setEmail("");
        setPassword("");
        await Navigate("/Generate");
      }
    } catch (error) {
      setError("Login Error: " + error.message); // Set error message
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login">
      <h1>Welcome back!</h1>
      <div className="email-input-container input">
        <FontAwesomeIcon className="login-input" icon={faEnvelope} />
        <input
          type="text"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-mail"
          className="transparent-input"
        />
      </div>
      <div className="password-input-container input">
        <FontAwesomeIcon icon={faKey} className="" />
        <input
          type="password"
          name="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          className="transparent-input"
        />
      </div>
      <div
        className="transparent-btn"
        onClick={handleSubmit}
        disabled={loading}
      >
        {loading ? (
          <>
            <i className="fa fa-spinner fa-spin"></i> Logging in...
          </>
        ) : (
          "Login"
        )}
      </div>
      <div className="middle-text">or</div>
      <div className="color-2" onClick={() => Navigate("/register")}>
        Register
      </div>

      {error && <p style={{ color: "#f1f1f16b" }}>{error}</p>} {/* Display error message */}
    </div>
  );
};

export default Login;
