import React, { useState } from 'react';

function NavbarToggle() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavIcon = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarScroll"
        aria-controls="navbarScroll"
        aria-expanded={isOpen ? 'true' : 'false'}
        onClick={toggleNavIcon}
      >
        <div
          id="nav-icon"
          className={isOpen ? 'open' : 'closed'}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </button>
      <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`} id="navbarScroll">
        {/* Add your navigation items here */}
        <ul className="navbar-nav">
          <li className="nav-item">
          </li>
          <li className="nav-item">
          </li>
          {/* Add more items as needed */}
        </ul>
      </div>
    </div>
  );
}

export default NavbarToggle;
