import React, { useState, useEffect } from "react";
import "./Generate.css";
import "./Generate-Style.css";
import axios from 'axios';
import FooterNav from './FooterNav'; // Make sure to provide the correct path to your FooterNav component
import { useNavigate } from 'react-router-dom';
import { FaBolt } from "react-icons/fa";
import firebase from "../firebase/firebaseConfig";

const Generate = () => {
    const navigate = useNavigate();

    // ... Generate and Nevigate codes start from here ...
    const [isLoading, setLoading] = useState(false);
    const [isLoading2, setLoading2] = useState(false);

    const [userId, setUserId] = useState("");
    const [userName, setUserName] = useState("");
    const [planType, setPlanType] = useState("");
    const [subscription, setSubscription] = useState(null);


    // useEffect to fetch user and subscription data
    useEffect(() => {
        // Check if the user is authenticated
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                // Retrieve user subscription data from Firebase Realtime Database
                const userRef = firebase.database().ref(`users/${user.uid}`);
                userRef.on('value', (snapshot) => {
                    const userData = snapshot.val();
                    if (userData) {
                        // Process user subscription data if needed
                    }
                });
            }
        });
    }, []);

    // useEffect to listen for changes in the user's authentication and subscription status
    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                // User is authenticated
                setUserId(user.uid);
                setUserName(user.displayName);

                // Reference to the user's data in the database
                const userRef = firebase.database().ref(`users/${user.uid}`);

                // Listener for changes in the user's data
                userRef.on("value", (snapshot) => {
                    const userData = snapshot.val();
                    console.log("User data from Firebase:", userData);

                    if (userData) {
                        // Process user data
                        setSubscription(userData.subscription || null);
                    } else {
                        setSubscription(null);
                    }

                    if (userData && userData.subscription) {
                        // User has a subscription, set the plan type
                        setPlanType(userData.subscription.planType || "free");
                    } else {
                        // User does not have a subscription
                        setPlanType("free");
                    }
                });
            } else {
                // User is not authenticated, reset user-related state
                setUserId("");
                setUserName("");
                setPlanType("");
                setSubscription(null);
            }
        });

        // Unsubscribe when the component is unmounted or when userId changes
        return () => unsubscribe();
    }, [userId]);


    // Effect to add the scroll lock class when isLoading2 is true
    useEffect(() => {
        if (isLoading2) {
            document.body.classList.add('disable-scroll');
        } else {
            document.body.classList.remove('disable-scroll');
        }

        // Cleanup function to remove the scroll lock class when component unmounts or isLoading2 changes
        return () => {
            document.body.classList.remove('disable-scroll');
        };
    }, [isLoading2]);

    // ... other code ...

    const handleCombinedAction = async () => {

        setLoading(true);
        // Check if the user is not subscribed before allowing the download
        if (!userId) {
            // User is not subscribed, navigate to the /register manually 
            navigate("/register")
        } else {
            // Delay for 1 second (1000 milliseconds) before setting loading to false
            setTimeout(() => {
                setLoading(false);

                // Now set the second loading state
                setLoading2(true);
                // Add the disable-scroll class to the body
                document.body.classList.add('disable-scroll');

            }, 2000);


            try {
                const response = await axios.get(`https://server-backend-99j3.onrender.com/generate-image`, {
                    params: { prompt, inputValue, negativePrompt, selected_model_type, seed, step, Prompt_Plus, model_type },
                    responseType: 'arraybuffer',
                });

                const blob = new Blob([response.data], { type: 'image/png' });
                const imageUrl = URL.createObjectURL(blob);

                // Simulate some asynchronous action
                setTimeout(() => {
                    setLoading(false);
                }, 400);

                // Simulate some asynchronous action
                setTimeout(() => {
                    setLoading2(false);
                }, 4000);

                // Navigate to "/register" after generating the image
                navigate("/Generation");
                // Call your handleGenerate function

                // Pass imageUrls to OtherPage when navigating
                navigate("/Generation", { state: { imageUrls: [...imageUrls, imageUrl] } });
            } catch (error) {
                console.error('Error generating image:', error);
            }
        }
    };

    // ... Generate and Nevigate codes end from here ...

    const [characterCount, setCharacterCount] = useState(500);
    const [remainingCharacters, setRemainingCharacters] = useState(500);
    const [inputValue, setInputValue] = useState('Girl on the bed, underwear, brown hair, cleavage, parted lips, bare shoulders'); // Set the initial value here


    const updateCharacterCount = (event) => {
        const inputText = event.target.value;
        const count = inputText.length;
        const remaining = characterCount - count;
        setRemainingCharacters(remaining);
        setInputValue(inputText);
    };

    const handleCancel = () => {
        setPrompt("");
        setInputValue('');
        setRemainingCharacters(characterCount);
    };


    const [clickedBox, setClickedBox] = useState(null);

    const handleBoxClick = (boxId) => {
        setClickedBox(boxId === clickedBox ? null : boxId);
    };

    const [prompt, setPrompt] = useState('Girl on the bed, underwear, brown hair, cleavage, parted lips, bare shoulders');
    const [Prompt_Plus, setPrompt_Plus] = useState(""); // Initial Prompt_Plus state
    const [model_type, setmodel_type] = useState(""); // Initial Prompt_Plus state
    const [negativePrompt, setNegativePrompt] = useState('');
    const [seed, setseed] = useState('');
    const [step, setstep] = useState('');
    const [selected_model_type, setselected_model_type] = useState('');
    const [imageUrls, setImageUrls] = useState([]);


    const modelclick = (option) => {
        setselected_model_type(option);

        if (option === "Realistic_Vision_V4.0.safetensors [29a7afaa]") {
            setPrompt_Plus("wearing red color less cover Tube Top on the top & less cover shorts on the bottom, full body detailed,face,(moisture body:1.3),(freckles:0),(large breasts:1.2), (wearing formal clothes:1.2)");
        } else if (option === "dreamshaper_8.safetensors [9d40847d]") {
            setPrompt_Plus("full body detailed,face,(moisture body:1.3),(freckles:0),(large breasts:1.2)");
        } else if (option === "anythingV5_PrtRE.safetensors [893e49b9]") {
            setPrompt_Plus("");
        } else if (option === "Realistic_Vision_V5.0.safetensors [614d1063]") {
            setPrompt_Plus("full body detailed,face,(moisture body:1.3),(freckles:0),(large breasts:1.3),(naked:1.2)");
        }
    };

    const [isClickedAsian, setIsClickedAsian] = useState(false);
    const [isClickedKorean, setIsClickedKorean] = useState(false);

    const modeltypeclick = (option) => {
        if (option === "Asian") {
            setIsClickedAsian(true);
            setIsClickedKorean(false); // Unselect the other option
            setmodel_type("(detailed face:1.2)");
        } else if (option === "Korean") {
            setIsClickedAsian(false); // Unselect the other option
            setIsClickedKorean(true);
            setmodel_type("Korean Girl");
        }
    };

    useEffect(() => {
        // Set the default selected option here
        setselected_model_type("Realistic_Vision_V4.0.safetensors [29a7afaa]");
        setPrompt_Plus("wearing red,black color less cover Tube Top on the top & less cover shorts on the bottom, full body detailed,face,(moisture body:1.3),(freckles:0.2),(large breasts:1.2)");
    }, []); // Empty dependency array to run this effect only once, when the component mounts


    return (
        <body>
            <div className="Generate">
                {/* Home Layer Start From Here */}
                <>
                    <FooterNav /> {/* Include the FooterNav component */}
                    <div className="Generation-hero">
                        <div className="hero-contents">
                            <div className="search-input">
                                <div className="Search-hero">
                                    <textarea
                                        id="prompt"
                                        placeholder="Enter Prompt"
                                        onInput={updateCharacterCount}
                                        maxLength={characterCount}
                                        value={prompt}
                                        onChange={(e) => setPrompt(e.target.value)}
                                    />
                                    <div className="character-info" style={{ display: inputValue ? 'block' : 'none' }}>
                                        <div className="word-count" id="character-count">
                                            {remainingCharacters}
                                        </div>
                                        <span className="cancel-button" onClick={(e) => { e.stopPropagation(); handleCancel(); }}>
                                            <i className="fas fa-times-circle" />
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="Display-models">
                                <div className="row">
                                    <s className="model-title">AI Girl Style</s>

                                    <div className="hero-model-like">
                                        <div className={` model-img ${selected_model_type === "Realistic_Vision_V4.0.safetensors [29a7afaa]" ? "selected" : ""}`} onClick={() => modelclick("Realistic_Vision_V4.0.safetensors [29a7afaa]")} style={{ opacity: selected_model_type === "Realistic_Vision_V4.0.safetensors [29a7afaa]" ? 1 : 0.5 }}>
                                            <img src={require('./assets/images/Type-model..webp')} className={`image--cover ${selected_model_type === "Realistic_Vision_V4.0.safetensors [29a7afaa]" ? "bordered" : ""}`} alt="Realistic Vision" />
                                            <p>Real V1.1</p>
                                        </div>

                                        <div className={` model-img ${selected_model_type === "dreamshaper_8.safetensors [9d40847d]" ? "selected" : ""}`} onClick={() => modelclick("dreamshaper_8.safetensors [9d40847d]")} style={{ opacity: selected_model_type === "dreamshaper_8.safetensors [9d40847d]" ? 1 : 0.5 }}>
                                            <img src={require('./assets/images/Type-model2..webp')} className={`image--cover ${selected_model_type === "dreamshaper_8.safetensors [9d40847d]" ? "bordered" : ""}`} alt="Dreamshaper Diffusion" />
                                            <p>Real V1.2</p>
                                        </div>

                                        <div className={` model-img ${selected_model_type === "anythingV5_PrtRE.safetensors [893e49b9]" ? "selected" : ""}`} onClick={() => modelclick("anythingV5_PrtRE.safetensors [893e49b9]")} style={{ opacity: selected_model_type === "anythingV5_PrtRE.safetensors [893e49b9]" ? 1 : 0.5 }}>
                                            <img src={require('./assets/images/Type-model3.webp')} className={`image--cover ${selected_model_type === "anythingV5_PrtRE.safetensors [893e49b9]" ? "bordered" : ""}`} alt="Anything v5" />
                                            <p>Anime V1</p>
                                        </div>

                                        <div className={` model-img ${selected_model_type === "Realistic_Vision_V5.0.safetensors [614d1063]" ? "selected" : ""}`} onClick={() => modelclick("Realistic_Vision_V5.0.safetensors [614d1063]")} style={{ opacity: selected_model_type === "Realistic_Vision_V5.0.safetensors [614d1063]" ? 1 : 0.5 }}>
                                            <img src={require('./assets/images/Type-model4..webp')} className={`image--cover ${selected_model_type === "Realistic_Vision_V5.0.safetensors [614d1063]" ? "bordered" : ""}`} alt="Dreamshaper Diffusion" />
                                            <p>NSFW V1.1</p>
                                        </div>
                                    </div>

                                    <s className="model-title">Look like<p>(not required)</p></s>
                                    <div className="hero-model-like">

                                        <div className="model-img">
                                            <div className="pro-tag">Pro</div>
                                            <box id="myBox" className="model--cover box" onClick={() => navigate("/Subscribe")}>
                                                {" "}
                                                <i className="fa fa-plus" aria-hidden="true" />
                                            </box>
                                        </div>

                                        <div className={`model-img ${isClickedAsian ? 'selected' : ''}`} onClick={() => modeltypeclick("Asian")}>
                                            <img
                                                src={require('./assets/images/lookalike-01..webp')}
                                                className={`model--cover ${isClickedAsian ? 'bordered2' : ''}`}
                                                alt="Realistic Vision"
                                            />
                                        </div>

                                        <div className={`model-img ${isClickedKorean ? 'selected' : ''}`} onClick={() => modeltypeclick("Korean")}>
                                            <img
                                                src={require('./assets/images/lookalike-02..webp')}
                                                className={`model--cover ${isClickedKorean ? 'bordered2' : ''}`}
                                                alt="Dreamshaper Diffusion"
                                            />
                                        </div>
                                    </div>

                                    <div className="text-hero">
                                        <div className="negative_prompt_text">
                                            <p>Enter Negative Prompt</p>
                                            <input
                                                type="text"
                                                id="negative_prompt"
                                                name="negative_prompt"
                                                placeholder="negative prompt"
                                                value={negativePrompt} onChange={(e) => setNegativePrompt(e.target.value)}
                                            />
                                        </div>
                                        <div className="bottom-inputs">
                                            <div className="input-contents">
                                                <p>Seeds</p>
                                                <input type="text" id="" name="seeds" placeholder={100} value={seed} onChange={(e) => setseed(e.target.value)} />
                                            </div>
                                            <div className="input-contents">
                                                <p>step</p>
                                                <input type="text" id="" name="step" placeholder={30} value={step} onChange={(e) => setstep(e.target.value)} />
                                            </div>
                                            <div className="btn-onof-contents">
                                                <p>2x Inhance</p>
                                                <label className="btn-onoff">
                                                    <input
                                                        type="checkbox"
                                                        name="name"
                                                        data-onoff="toggle"
                                                        defaultChecked=""
                                                    />
                                                    <span />
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </>
                <div className="hei-180"></div>
                <div className="section-header text-center hei-180">
                    <h2>How to Get AI Generated Images in 3 Steps?</h2>
                </div>
                {/*  How it works steps Section START from here */}
                <section id="How-it-works">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-4 Center">
                                <div className="steps-col">
                                    <div className="row-number">
                                        <box className="box-transparent">
                                            <img className="icon" src={require('./assets/images/sample-hero-1..webp')} alt="simple-steps" />
                                        </box>
                                    </div>
                                    <div className="steps-content">
                                        <div className="steps-title">
                                            <span>1. Choose your Lookalike</span>
                                        </div>
                                        <s className="description">
                                            Choose Model Lookalike AI character or Upload your own look to Generate AI images
                                        </s>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4 Center">
                                <div className="steps-col">
                                    <div className="row-number">
                                        <box className="box">
                                            <div className="col">
                                                <div className="" id="col-loader">
                                                    <span />
                                                    <span />
                                                    <span />
                                                </div>
                                            </div>
                                        </box>
                                    </div>
                                    <div className="steps-content">
                                        <div className="steps-title">
                                            <span>2. AI starts generating</span>
                                        </div>
                                        <s className="description">
                                            Our AI will start learning from your prompts and lookalike and create an AI image of you.
                                        </s>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4 Center">
                                <div className="steps-col">
                                    <div className="row-number">
                                        <box className="box-transparent">
                                            <img className="icon" src={require('./assets/images/models..webp')} alt="simple-steps" />
                                        </box>
                                    </div>
                                    <div className="steps-content">
                                        <div className="steps-title">
                                            <span>3. Create unlimited photos</span>
                                        </div>
                                        <s className="description">
                                            You can now create unlimited AI images without any barrier
                                        </s>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*  How it works steps Section END from here */}

                {/*  About Us Section START from here */}
                <section id="About-us">
                    <div className="container">
                        <div className="row">
                            <p className="About-us-mob-text">Pure Relasitic</p>
                            <div className="About-us-mob-img col-lg-6">
                                <img className="Center" src={require('./assets/images/Samples-hero..webp')} alt="About-img" />
                            </div>
                            <div className="col-lg-6 About-us-left Center">
                                <h2 className="title">
                                    Generate Real images that Seems Rare
                                </h2>
                                <p className="des">
                                    Deepictures Generate more realistic images that look very real! You are just a few steps away from generating pure realistic images with simple methods
                                </p>
                                <div className="About-hero-btn">
                                    <button className="btn-hover color-4" type="button" onClick={() => navigate("/Subscribe")}>
                                        Free trail now
                                        <i className="fas fa-arrow-right" />
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-6 About-us-right">
                                <div className="About-us-right-img">
                                    <img className="Center" src={require('./assets/images/Samples-hero..webp')} alt="About-img" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*  About Us Section END from here */}

                <div className="hero-gen-button">
                    {/* Call handleCombinedAction when button is clicked */}
                    <button onClick={handleCombinedAction} className="color-1" disabled={isLoading}>
                        {isLoading ? (
                            <span>
                                <i className="fa fa-spinner fa-spin"></i>
                                Generation...
                            </span>
                        ) : (
                            'Generate'
                        )}
                    </button>
                </div>
                {isLoading2 ? (
                    <div className="loading-indicator">
                        {/* Use the FaSpinner component for the loading icon */}
                        <div className="box">
                            <div className="clock" />
                            <s className="loading-indicator-text">Generating . . .</s>
                            <div className="space"></div>
                            <button className="color-5" onClick={() => navigate("/Subscribe")}> Generate Faster<span><FaBolt /></span> </button>
                        </div>
                    </div>
                ) : (
                    ''
                )}

                {/* FAQ QNA Section START from here */}

                {/* FOOTER Section START from here */}
                <footer className="gen-site-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <p className="copyright-text">
                                    Copyright © 2023 All Rights Reserved by
                                    <a href="/">Deep Pvt</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </footer>

                {/* FOOTER Section END from here */}
            </div>
        </body>
    );
};

export default Generate;
