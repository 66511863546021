import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { FaTrashAlt, FaDownload, FaRedoAlt, FaLongArrowAltLeft } from 'react-icons/fa';
import "./otherPage.css";
import { useNavigate } from "react-router-dom";
import firebase from "../firebase/firebaseConfig";

const OtherPage = () => {
    const Navigate = useNavigate();
    const location = useLocation();
    const initialImageUrls = location.state?.imageUrls || [];
    const [imageUrls, setImageUrls] = useState(initialImageUrls);
    const [userId, setUserId] = useState("");
    const [userName, setUserName] = useState("");
    const [planType, setPlanType] = useState("");
    const [subscription, setSubscription] = useState(null);

    // useEffect to listen for changes in the user's authentication and subscription status
    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                // User is authenticated
                setUserId(user.uid);
                setUserName(user.displayName);

                // Reference to the user's data in the database
                const userRef = firebase.database().ref(`users/${user.uid}`);

                // Listener for changes in the user's data
                userRef.on("value", (snapshot) => {
                    const userData = snapshot.val();
                    console.log("User data from Firebase:", userData);

                    if (userData) {
                        // Process user data
                        setSubscription(userData.subscription || null);
                    } else {
                        setSubscription(null);
                    }

                    if (userData && userData.subscription) {
                        // User has a subscription, set the plan type
                        setPlanType(userData.subscription.planType || "free");
                    } else {
                        // User does not have a subscription
                        setPlanType("free");
                    }
                });
            } else {
                // User is not authenticated, reset user-related state
                setUserId("");
                setUserName("");
                setPlanType("");
                setSubscription(null);
            }
        });

        // Unsubscribe when the component is unmounted or when userId changes
        return () => unsubscribe();
    }, [userId]);

    useEffect(() => {
        if (imageUrls && window.innerWidth < 960) {
            document.body.classList.add('disable-scroll');
        } else {
            document.body.classList.remove('disable-scroll');
        }

        return () => {
            document.body.classList.remove('disable-scroll');
        };
    }, [imageUrls]);

    const handleDeleteImage = (index) => {
          // Check if the user is not subscribed before allowing the download
    if (!userId || (userId && planType.toLowerCase() !== 'pro')) {
          // User is not subscribed, navigate to the /subscribe manually 
           Navigate("/Subscribe")
    } else {
        const updatedImageUrls = imageUrls.filter((_, i) => i !== index);
        setImageUrls(updatedImageUrls);
    }
    
    };

    const generateRandomString = (length) => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            result += characters.charAt(randomIndex);
        }
        return result;
    };

    const handleDownloadImage = (imageUrl, index) => {
    // Check if the user is not subscribed before allowing the download
    if (!userId || (userId && planType.toLowerCase() !== 'pro')) {
         // User is not subscribed, show a message or handle it accordingly
        console.log("Download is not allowed for normal users");
          // User is not subscribed, navigate to the /subscribe manually 
           Navigate("/Subscribe")
    } else {
          // Create an anchor element
        const link = document.createElement('a');
        link.href = imageUrl;
        link.target = '_blank';
        link.rel = 'noopener noreferrer';
        // Set the download attribute with a unique name
        link.download = `generated_image_${generateRandomString(8)}.png`;

        // Dispatch a click event on the anchor element
        const event = new MouseEvent('click');
        link.dispatchEvent(event);
    }
};

    return (
        <body>
            <div className={`otherPage ${userId && subscription && subscription.planType.toLowerCase() === 'pro' ? '' : 'blur'}`}>
                <div className="row justify-content-center">
                    <div className="custom-image-container" id="imageContainer">
                        {imageUrls.map((url, index) => (
                            <div key={index} className='hero-gen-image'>
                                <img src={url} alt={`Generated ${index}`} />
                                <div className='back-btn'>
                                    <button onClick={() => Navigate("/Generate")}>
                                        <FaLongArrowAltLeft />
                                    </button>
                                </div>
                                <div className='hero-gen-btn'>
                                    <button onClick={() => handleDeleteImage(index)}>
                                        <FaTrashAlt />
                                    </button>
                                    <button onClick={() => handleDownloadImage(url, index)}>
                                        <FaDownload />
                                    </button>
                                </div>
                                {(!userId || (userId && planType.toLowerCase() !== "pro")) && (
                                    <div className={`remove-blur-btn ${userId && planType.toLowerCase() !== "pro" ? 'blur' : ''}`} onClick={() => Navigate("/Subscribe")}>
                                        <button>Remove blur</button>
                                    </div>
                                )}

                                <div className='bottom-hero'>
                                    <div className='Try-again-btn' onClick={() => Navigate("/Generate")}>
                                        <s>Try Again <FaRedoAlt className='FaRedoAlt' /></s>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </body>
    );
};

export default OtherPage;
