import React, { useState } from 'react';
const email = 'savarnasumit@gmail.com';
const subject = 'Deepictures Support';

const sendEmail = () => {
  window.location.href = `mailto:${email}?subject=${subject}`;
};

const FAQSection = () => {
  const faqItems = [
    {
      question: 'Can i use it on Social media ?',
      answer:
        'Yes, you are permitted to use your images wherever you like. It may be used on your website, business cards, social media pages, and other platforms.',
    },
    {
      question: 'Can I get a refund ?',
      answer:
        "Yes, as long as you haven't trained the AI yet, we may refund orders made during the first 14 days. It is no longer possible to get a refund for a plan once it has been utilized or after 14 days. For further information, please see our Terms of Service.",
    },
    {
      question: 'Are payments secure ?',
      answer:
        'Yes, we accept payments via Stripe. No details about your credit card are kept on database by us.',
    },
    {
      question: 'Can I get an invoice ?',
      answer: 'A bill invoice for your purchase is available, yes.',
    },
  ];

  const [activeIndex, setActiveIndex] = useState(0);

  const toggleAccordion = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <section id="faq">
      <div className="container">
        <div className="section-header text-center">
          <div className="faq-container">
            <h2>Frequently Asked Questions</h2>
            <p>Don’t get your answer ?</p>
            <p>
              Feel free to leave a{' '}
              <a href={`mailto:${email}?subject=${subject}`} onClick={sendEmail}>
                message
              </a>
            </p>
          </div>
          <div className="accordion">
            <div className="row">
              <div className="col-lg-6">
                <div className="faq-content">
                  <div className="row">
                    {faqItems.map((item, index) => (
                      <div className="accordion-item" key={index}>
                        <button
                          id={`accordion-button-${index}`}
                          aria-expanded={activeIndex === index ? 'true' : 'false'}
                          onClick={() => toggleAccordion(index)}
                        >
                          <span className="accordion-title">{item.question}</span>
                          <span className="fas fa-angle-down" aria-hidden="true" />
                        </button>
                        <div
                          className={`accordion-content ${activeIndex === index ? 'expanded' : 'collapsed'
                            }`}
                        >
                          <p>{item.answer}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 Faq-right">
                <div className="Faq-right-img">
                  <img className="Center"
                    src={require('./assets/images/Hero-img..webp')}
                    alt="faq-hero-img"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
