import React from 'react';
import './CustomerSupport.css'; // Import your CSS file
import { Helmet } from 'react-helmet';
import FooterNav from '../FooterNav/FooterNav';

const email = 'Feedback@deepictures.com'; // Change this to your support email address
const subject = 'Customer Support Inquiry';

const sendEmail = () => {
  window.location.href = `mailto:${email}?subject=${subject}`;
};

function CustomerSupport() {
  return (
    <div className="Customer-Support">
      <Helmet>
        <title>Customer Support - Deepictures</title>
        <meta name="description" content="Contact our customer support for assistance with any inquiries or issues." /> {/* Set the meta description */}
      </Helmet>
      <FooterNav /> {/* Include the FooterNav component */}
      <div className="container mt-5">
        <h1 className="display-4 text-center">Customer Support</h1>

        <div className="customer-support-content">
          <div className="customer-support-paragraph">
            <p className="span">
              Welcome to Customer Support at Deepictures (we, us or our). We are here to assist you and provide the support you need to ensure a smooth and enjoyable experience while using our services.
            </p>
            <p className="span">
              If you have any questions, encounter issues, or require assistance of any kind, please don't hesitate to reach out to us. This page outlines how to get in touch with our customer support team and provides information about the assistance we can provide.
            </p>
          </div>

          <h2>1. Contacting Customer Support</h2>
          <p>
            We are dedicated to helping you with any inquiries or concerns you may have. To reach our Customer Support team, please use the following contact information:
          </p>

          <ul>
            <li>
              <strong>Email:</strong> You can contact us via email at{' '}
              <a href={`mailto:${email}?subject=${subject}`} onClick={sendEmail}>
                {email}
              </a>. We aim to respond to your email promptly and provide the assistance you need.
            </li>
          </ul>

          <p>
            Our Customer Support team is available during our business hours to address your questions and resolve any issues you may encounter while using our services.
          </p>

          <h2>2. How We Can Assist You</h2>
          <p>
            Our Customer Support team is here to assist you with a wide range of issues and questions, including but not limited to:
          </p>

          <ul>
            <li>
              <strong>Technical Support:</strong> If you encounter technical difficulties while using our website or services, our team can provide guidance and solutions to resolve the issue.
            </li>
            <li>
              <strong>Account Assistance:</strong> Whether you have account-related questions or need assistance with account settings, we are here to help you manage your profile effectively.
            </li>
            <li>
              <strong>Service Inquiries:</strong> If you have questions about our services, features, or how to use specific tools, our team can provide information and guidance.
            </li>
            <li>
              <strong>Feedback and Suggestions:</strong> We value your feedback and welcome your suggestions for improving our services. Feel free to share your thoughts with us.
            </li>
          </ul>

          <p>
            Our goal is to ensure your satisfaction and address any concerns you may have promptly and effectively. Please don't hesitate to reach out to us with any questions or assistance you may need.
          </p>

          <h2>3. Privacy and Data Security</h2>
          <p>
            Your privacy and data security are important to us. Rest assured that any information you provide to our Customer Support team will be treated with the utmost confidentiality and used solely for the purpose of assisting you. For more details on how we handle customer data, please refer to our <a href="/privacy-policy">Privacy Policy</a>.
          </p>
        </div>
      </div>

      {/* FOOTER Section START from here */}
      <footer className="gen-site-footer">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p className="copyright-text">
                Copyright © 2023 All Rights Reserved by
                <a href="/">Deep Pvt</a>
              </p>
            </div>
          </div>
        </div>
      </footer>

      {/* FOOTER Section END from here */}
    </div>
  );
}

export default CustomerSupport;
