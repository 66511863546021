import React, { useState } from "react";
import "./register.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import firebase from "../firebase/firebaseConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faEnvelope, faKey } from "@fortawesome/free-solid-svg-icons";

const Register = () => {
  const [fullname, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null); // New state for error
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    setLoading(true);
    setError(null); // Reset error state on each submission
    e.preventDefault();

    try {
      if (!email || !fullname || !password) {
        throw new Error("Please fill in all fields");
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        throw new Error("Please provide a valid email");
      }

      if (password.length < 6) {
        throw new Error("Password should be at least 6 characters");
      }

      const response = await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password);

      if (response.user) {
        await response.user.updateProfile({
          displayName: fullname,
        });

        const uid = response.user.uid;
        const userRef = firebase.database().ref("users/" + uid);

        await userRef.set({
          uid: uid,
          email: email,
          username: fullname,
        });

        setFullName("");
        setEmail("");
        setPassword("");
        navigate("/login");
      }
    } catch (error) {
      setError(error.message);
      console.error("Register error", error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="register">
      <h1>Register Now</h1>
      
      <div className="username-input-container input">
        <FontAwesomeIcon icon={faUser} className="" />
        <input
          type="text"
          name="name"
          placeholder="Your Name"
          required
          value={fullname}
          onChange={(e) => setFullName(e.target.value)}
          className="transparent-input"
        />
      </div>

      <div className="useremail-input-container input">
        <FontAwesomeIcon icon={faEnvelope} className="" />
        <input
          type="text"
          name="email"
          placeholder="Your Email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="transparent-input"
        />
      </div>

      <div className="user-password input">
        <FontAwesomeIcon icon={faKey} className="" />
        <input
          type="password"
          name="password"
          placeholder="Your Password"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="transparent-input"
        />
      </div>

      <div
        className="transparent-btn"
        onClick={handleSubmit}
        disabled={loading}
      >
        {loading ? <i className="fa fa-spinner fa-spin"></i> : "Register"}
      </div>
      <div>or</div>
      <div className="color-2" onClick={() => navigate("/login")}>
        Login
      </div>

       {error && <p style={{ color: "#f1f1f16b" }}>{error}</p>} {/* Display error message */}
    </div>
  );
};

export default Register;
