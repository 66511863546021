import React, { useState, useEffect } from "react";
import "./Payment.css";
import FooterNav from './FooterNav'; // Make sure to provide the correct path to your FooterNav component
import { useNavigate } from 'react-router-dom';

const Payment = () => {
    const navigate = useNavigate();

    const [proUser, setProUser] = useState(false);

    // Simulate the user's Pro status verification
    useEffect(() => {
        // Check local storage or cookies for the user's Pro status
        const storedProStatus = localStorage.getItem('proUser');
        if (storedProStatus === 'true') {
            setProUser(true);
        }
    }, []);

    // This function will be called when the user subscribes
    function handleSubscribe() {
        // Simulate a successful subscription payment
        // In a real scenario, this would initiate the PayPal subscription payment process

        // Assuming payment is successful
        // Update the user's Pro status in local storage or cookies
        localStorage.setItem('proUser', 'true');
        setProUser(true);

        // Navigate to a new page or route after successful subscription
        navigate('/Generate'); // Replace with the desired route
    }

    return (
        <body>
            <FooterNav /> {/* Include the FooterNav component */}
            <div className="container Payment">
                <div className="row justify-content-center">
                    <div className="col-lg-6">

                        <header className="site-header" id="header">
                            <h6 className="site-header__title" data-lead-id="site-header-title">
                                <span>
                                    <i className="fa fa-spinner fa-spin"></i> Working on it
                                </span>
                            </h6>
                        </header>
                        <div className="main-content">
                            <p className="main-content__body" data-lead-id="main-content-body">
                                Once the payment verification process is successfully completed, click the "Submit" button. This action signifies your acknowledgment that the payment details are accurate, and you authorize the transaction to proceed. By clicking "Submit," you're confirming your role in the payment process and allowing us to fulfill your Premium subscription request.
                            </p>
                        </div>

                        <hr />
                        <p>
                            Having trouble? <a href="https://bootstrapcreative.com/">Contact us</a>
                        </p>
                        <button className="btn btn-primary btn-sm" onClick={handleSubscribe}> Submit to Continue</button>
                    </div>
                </div>
            </div>
        </body>
    );
};

export default Payment;
