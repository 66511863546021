import React, { Component } from 'react';
import './TextAnimation.css';

class TextAnimation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      textContent: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.text !== this.props.text) {
      this.animateText();
    }
  }

  componentDidMount() {
    this.animateText();
  }

  animateText() {
    const { text } = this.props;

    this.setState({ textContent: '' }, () => {
      // Delay the animation start to ensure textContent is empty
      setTimeout(() => {
        this.setState({ textContent: text });
      }, 0);
    });
  }

  render() {
    const { textContent } = this.state;

    return (
        <div className='writer-text-hero'>
      <p className="type-writer-text">
        {textContent.split('').map((char, index) => (
          <span key={index} className="type-writer-char">{char}</span>
        ))}
      </p>
      </div>
    );
  }
}

export default TextAnimation;
