import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaRegUserCircle } from 'react-icons/fa';
import { RightOutlined } from '@ant-design/icons';
import firebase from '../firebase/firebaseConfig';
import NavbarToggle from './Navbar-toggler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrown } from '@fortawesome/free-solid-svg-icons';

const FooterNav = () => {
    const navigate = useNavigate();
    const [userId, setUserId] = useState("");
    const [userName, setUserName] = useState("");
    const [planType, setPlanType] = useState("");

    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                setUserId(user.uid);
                setUserName(user.displayName);

                const userRef = firebase.database().ref("users/" + user.uid);

                userRef.on("value", (snapshot) => {
                    const user = snapshot.val();
                    if (user && user.subscription) {
                        setPlanType(user.subscription.planType || "");
                    } else {
                        setPlanType("");
                    }
                });
            } else {
                setUserId("");
                setUserName("");
                setPlanType("");
            }
        });

        return () => unsubscribe();
    }, []);

    return (
        <div className="footer-nav">
            <nav className="navbar navbar-expand-lg bg-body-tertiary">
                <div className="container position-relative">
                    <div className="navbar-brand">
                        <a className="nav-logo" href="/">
                            <img
                                className="favicon-logo"
                                src={require('./assets/images/favicon-logo..webp')}
                                alt="favicon-logo"
                            />
                            Deepictures
                        </a>
                    </div>
                    <NavbarToggle />
                    <div className="collapse navbar-collapse" id="navbarScroll">
                        <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll">
                            <div className="nav-content">
                                <li className="nav-item nav-visible">
                                    <a className="nav-link nav-pro" href="/">
                                        <FaRegUserCircle className="user-icon" />
                                        {userId ? userName : 'Guest'}
                                    </a>
                                </li>
                            </div>

                            <li className="nav-item">
                                <a className="nav-link" href="/terms-of-Service">
                                    <RightOutlined className="info-icon" /> About
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/">
                                    <RightOutlined className="info-icon" /> How it works
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/Subscribe">
                                    <RightOutlined className="info-icon" /> Pricing
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/">
                                    <RightOutlined className="info-icon" /> Blog
                                </a>
                            </li>
                            <li className="nav-item not-visible">
                                <a className="nav-link" href="/Subscribe">
                                    Pricing
                                </a>
                            </li>
                        </ul>
                        <div className="footer-buttons Premium">
                            <div>
                                {firebase.auth().currentUser ? (
                                    <button
                                        type="button"
                                        className="btn-hover"
                                        onClick={async () => {
                                            await firebase.auth().signOut();
                                            navigate('/');
                                        }}
                                    >
                                        Logout
                                    </button>
                                ) : (
                                    <button
                                        type="button"
                                        className="btn-hover"
                                        onClick={() => navigate('/login')}
                                    >
                                        Login
                                    </button>
                                )}

                                {userId ? (
                                    planType.toLowerCase() === "pro" ? (
                                        <button
                                            type="button"
                                            className="btn-hover hero-btn-color"
                                            onClick={() => navigate('/profile')}
                                        >
                                            <FontAwesomeIcon icon={faCrown}  style={{ margin: '0 6px'}} />
                                            Premium $
                                        </button>
                                    ) : (
                                        <button
                                            type="button"
                                            className="btn-hover btn-Pro"
                                             onClick={() => navigate('/subscribe')}
                                        >
                                            <FontAwesomeIcon icon={faCrown}  style={{ margin: '0 6px'}} />
                                            Premium ?
                                        </button>
                                    )
                                ) : (
                                    <button
                                        type="button"
                                        className="btn-hover hero-btn-color"
                                        onClick={() => navigate('/register')}
                                    >
                                        Register
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default FooterNav;
