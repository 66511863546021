import React from 'react';
import './Cancellation.css'; // Import your CSS file
import { Helmet } from 'react-helmet';
import FooterNav from '../FooterNav/FooterNav';

const email = 'Feedback@deepictures.com';
const subject = 'Cancellation & Refund Policy Inquiry';

const sendEmail = () => {
  window.location.href = `mailto:${email}?subject=${subject}`;
};

function Cancellation() {
  return (
    <div className="Cancellation-Refund-Policy">
      <Helmet>
        <title>Cancellation and Refund Policy - Deepictures</title>
        <meta name="description" content="Learn about our Cancellation and Refund Policy for the products or services offered on our website." /> {/* Set the meta description */}
      </Helmet>
      <FooterNav /> {/* Include the FooterNav component */}
      <div className="container mt-5">
        <h1 className="display-4 text-center">Cancellation & Refund</h1>

        <div className="cancellation-refund-policy-content">
          <div className="cancellation-refund-policy-paragraph">
            <p className="span">
              At Deepictures (we, us, or our), we strive to provide a seamless and transparent subscription experience. This Cancellation & Refund Policy outlines our approach to handling cancellations and refunds, ensuring that you have a clear understanding of your rights and responsibilities as a subscriber.
            </p>
            <p className="span">
              We encourage you to carefully read and familiarize yourself with this policy. If you have any questions or require further clarification, please do not hesitate to contact us. Our contact information is provided at the end of this policy. Additionally, please note that our Terms of Service apply to your subscription.
            </p>
          </div>

          <h2>1. Cancellation of Subscription</h2>
          <p>
            You have the right to cancel your subscription at any time, and there are no cancellation fees. To cancel your subscription, please contact us at{' '}
            <a href={`mailto:${email}?subject=${subject}`} onClick={sendEmail}>
              {email}
            </a>.
          </p>

          <p>
            Once you've canceled your subscription, you will continue to have access to the subscription benefits until the end of your current billing cycle. After the current billing cycle ends, your subscription will not be renewed, and you will no longer be charged.
          </p>

          <h2>2. Refund Policy</h2>
          <p>
            We understand that circumstances may arise that require a refund request. Here's how our refund policy works:
          </p>

          <ul>
            <li>
              <strong>Monthly Subscriptions:</strong> If you have a monthly subscription, you can request a refund within the first seven (7) days of your initial subscription purchase. After this period, we do not offer refunds for monthly subscriptions.
            </li>

            <li>
              <strong>Annual Subscriptions:</strong> For annual subscriptions, you can request a refund within the first thirty (30) days of your initial subscription purchase. After this period, we do not offer refunds for annual subscriptions.
            </li>
          </ul>

          <p>
            To request a refund, please contact us at{' '}
            <a href={`mailto:${email}?subject=${subject}`} onClick={sendEmail}>
              {email}
            </a>. Be sure to include your subscription details, such as the purchase date and transaction ID, to expedite the process.
          </p>

          <p>
            Please allow up to 2 business days for us to process your refund request. Refunds will be issued to the original payment method used for the subscription purchase.
          </p>

          <p>
            We reserve the right to deny refund requests that do not comply with our refund policy or if we suspect misuse of our subscription services.
          </p>

          <h2>3. Changes to Subscription Terms</h2>
          <p>
            We may update our subscription terms and pricing from time to time. Any changes will be communicated to you in advance, and you will have the option to accept or cancel your subscription before the changes take effect.
          </p>

          <p>
            By continuing to use our subscription services after the changes become effective, you indicate your acceptance of the updated terms and pricing.
          </p>

          <h2>4. Contact Us</h2>
          <p>
            If you have any questions, concerns, or requests regarding our Cancellation & Refund Policy or your subscription, please don't hesitate to contact us at{' '}
            <a href={`mailto:${email}?subject=${subject}`} onClick={sendEmail}>
              {email}
            </a>. We are here to assist you and address any inquiries you may have.
          </p>
        </div>
      </div>

      {/* FOOTER Section START from here */}
      <footer className="gen-site-footer">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p className="copyright-text">
                Copyright © 2023 All Rights Reserved by
                <a href="/">Deep Pvt</a>
              </p>
            </div>
          </div>
        </div>
      </footer>

      {/* FOOTER Section END from here */}
    </div>
  );
}

export default Cancellation;
