import React, { useEffect, useState } from "react";
import "./Subscribe.css";
import "./Subscribe-Style.css";
import { useNavigate } from "react-router-dom";
import firebase from "../firebase/firebaseConfig";

const firebaseConfig = {
    apiKey: "AIzaSyDbNo2_aH6Pl9DANyZ_QlxVZOBQ62_2xmA",
    authDomain: "stripe-subscription-abb4d.firebaseapp.com",
    projectId: "stripe-subscription-abb4d",
    // Add other Firebase config properties as needed
};

// Check if Firebase is not already initialized
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

const Subscribe = () => {
    const navigate = useNavigate();
    const [userId, setUserId] = useState("");
    const [userName, setUserName] = useState("");
    const [isSubscribed, setIsSubscribed] = useState(false);

    const handleOneTimePayment = () => {
        // Simulate a successful one-time payment
  window.location.href = 'https://www.paypal.com/paypalme/dannkest/9.99';
        // You can implement the actual payment processing logic here

        // Set the user's subscription status to 'pro' (for example)
        const user = firebase.auth().currentUser;
        if (user) {
            const userRef = firebase.database().ref("users/" + user.uid);

            userRef.update({
                subscription: {
                    planType: "pro",
                },
            });

            setIsSubscribed(true);

            // Example: Navigate to the desired page after payment
            navigate("/Generate");
        }
    };

    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                setUserId(user.uid);
                setUserName(user.displayName);

                const userRef = firebase.database().ref("users/" + user.uid);

                userRef.on("value", (snapshot) => {
                    const user = snapshot.val();
                    if (user && user.subscription && user.subscription.planType === "pro") {
                        setIsSubscribed(true);
                    } else {
                        setIsSubscribed(false);
                    }
                });
            } else {
                setUserId("");
                setUserName("");
                setIsSubscribed(false);
            }
        });

        return () => unsubscribe();
    }, [userId]);

    return (
        <div className="Subscribe">
            <div className="Generation-hero">
                <section className="pricing">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6 col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title text-muted text-uppercase text-center">
                                            Premium
                                        </h5>
                                        <h6 className="card-price text-center">
                                            $9.99<span className="period">/month</span>
                                        </h6>
                                        <hr />
                                        <ul className="fa-ul">
                                            <li>
                                                <span className="fa-li">
                                                    <i className="fa fa-check" />
                                                </span>
                                                <strong>Remove blur</strong>
                                            </li>
                                            <li>
                                                <span className="fa-li">
                                                    <i className="fa fa-check" />
                                                </span>
                                                Lifetime save
                                            </li>
                                             <li>
                                                <span className="fa-li">
                                                    <i className="fa fa-check" />
                                                </span>
                                                Generate Faster
                                            </li>
                                            <li>
                                                <span className="fa-li">
                                                    <i className="fa fa-check" />
                                                </span>
                                                No watermark
                                            </li>
                                            <li>
                                                <span className="fa-li">
                                                    <i className="fa fa-check" />
                                                </span>
                                                High quality image
                                            </li>
                                            <li>
                                                <span className="fa-li">
                                                    <i className="fa fa-check" />
                                                </span>
                                                <strong>Modify AI Girl's</strong> looks
                                            </li>
                                            <li>
                                                <span className="fa-li">
                                                    <i className="fa fa-check" />
                                                </span>
                                                Multi-creations generate
                                            </li>
                                            <li>
                                                <span className="fa-li">
                                                    <i className="fa fa-check" />
                                                </span>
                                                <strong>Fast Generation</strong>
                                            </li>
                                            <li>
                                                <span className="fa-li">
                                                    <i className="fa fa-check" />
                                                </span>
                                                100 credits per month
                                            </li>
                                            <li>
                                                <span className="fa-li">
                                                    <i className="fa fa-check" />
                                                </span>
                                                Custom Model
                                            </li>
                                        </ul>
                                        <div className="hero-Payment-button">
                                            {isSubscribed ? (
                                                <p>You are already subscribed to Premium.</p>
                                            ) : (
                                                <button onClick={handleOneTimePayment}>
                                                    Pay with <a>Paypal</a>
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default Subscribe;
